import React, { useState } from 'react';
import { Button } from 'theme-ui';

import { SVG_MARKUP } from './constants';
import InteractiveSvg from './components/interactiveSvg';

import * as Styled from './style';

const Patterns = () => {
  const [selectedPattern, setSelectedPattern] = useState(4);
  const [resetAllOverride, setResetAllOverride] = useState(false);

  const setButtonVariant = (variantPattern: number) => (
    variantPattern === selectedPattern ? 'primary' : 'secondary'
  );

  return(
    <Styled.OuterContainer>
      <Styled.InnerContainer isAllSelected={selectedPattern === -1}>
        <Styled.Nav>
          <div>
            <Button variant={setButtonVariant(4)} onClick={() => setSelectedPattern(4)}>4</Button>
            <Button variant={setButtonVariant(11)} onClick={() => setSelectedPattern(11)}>11</Button>
            <Button variant={setButtonVariant(12)} onClick={() => setSelectedPattern(12)}>12</Button>
          </div>

          <Button variant={setButtonVariant(-1)} onClick={() => setSelectedPattern(-1)}>Twenty-seven</Button>
        </Styled.Nav>

        <Styled.PatternsContainer>
          <Styled.PatternVisibility isSelected={selectedPattern === -1 || selectedPattern === 4}>
            <InteractiveSvg
              svgMarkup={SVG_MARKUP.four}
              isAllPatternsSelected={selectedPattern === -1}
              resetAllOverride={resetAllOverride}
              setResetAllOverride={setResetAllOverride}
            />
          </Styled.PatternVisibility>
          <Styled.PatternVisibility isSelected={selectedPattern === -1 || selectedPattern === 11}>
            <InteractiveSvg
              svgMarkup={SVG_MARKUP.eleven}
              isAllPatternsSelected={selectedPattern === -1}
              resetAllOverride={resetAllOverride}
              setResetAllOverride={setResetAllOverride}
            />
          </Styled.PatternVisibility>
          <Styled.PatternVisibility isSelected={selectedPattern === -1 || selectedPattern === 12}>
            <InteractiveSvg
              svgMarkup={SVG_MARKUP.twelve}
              isAllPatternsSelected={selectedPattern === -1}
              resetAllOverride={resetAllOverride}
              setResetAllOverride={setResetAllOverride}
            />
          </Styled.PatternVisibility>
        </Styled.PatternsContainer>

        {selectedPattern === -1 && (
          <Styled.Nav>
            <Button 
              variant="secondary"
              onClick={() => setResetAllOverride(true)}
              disabled={resetAllOverride === true}
            >
              Reset
            </Button>
          </Styled.Nav>
        )}
      </Styled.InnerContainer>
    </Styled.OuterContainer>
  );
};

export default {
  Component: Patterns, 
  title: 'Patterns?',
  createdAt: '2021-05-05',
  technique: 'SVG collaboration with <a target="_blank" rel="noopener noreferrer" href="https://dribbble.com/michellevandy">M. Vandy.</a>',
};