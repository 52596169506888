import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';
import * as Shared from 'components/style';

import * as SharedHome from '../style';

export const SectionNav = styled.div<{ theme: CustomTheme }>`
  ${SharedHome.gridBorderStyle()}
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color .25s ease-out;
  
  position: sticky; 
  top: calc(${Shared.PRIMARY_NAV_HEIGHT} + ${Shared.TICKER_HEIGHT});

  height: ${Shared.PRIMARY_NAV_HEIGHT};
  padding: 0 ${SPACER.base};
  display: flex; 
  justify-content: space-between;
  align-items: center;

  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 20px; 
  font-style: italic;

  a {
    text-decoration: none; 
  }

  a:hover {
    text-decoration: underline dashed;
	  text-decoration-skip-ink: none;
	  text-decoration-thickness: 1px;
  }
`;