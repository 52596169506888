import styled from '@emotion/styled';

import { MEDIA_QUERIES } from 'consts';

export const PatternContainer = styled.div`
  ${MEDIA_QUERIES.xlUp} {
    min-width: 400px;
  }

  > svg {
    width: 100%; 
    
    stroke: black; 
  
    > path {
      cursor: pointer; 
    }
  }
`;