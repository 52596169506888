import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

export const OuterContainer = styled.div`
  position: absolute; 
  width: 100%; 
  height: 100%; 

  display: flex; 
  justify-content: center; 
  align-items: center;
`;

export const InnerContainer = styled.div<{isAllSelected: boolean }>`
  padding: ${({ isAllSelected }) => isAllSelected ? SPACER.base : SPACER.x3large}; 
`;

export const Nav = styled.nav<{ theme: CustomTheme }>`
  margin: ${SPACER.xsmall} 0;
  display: flex; 
  justify-content: space-between;

  button {
    font-family: ${({ theme }) => theme.fonts.monospace};
    font-size: 12px;
    cursor: pointer;
  }

  button:not(:last-child) {
    margin-right: ${SPACER.xsmall};
  }
`;

export const PatternsContainer = styled.div`
  display: flex;
`;

export const PatternVisibility = styled.div<{ isSelected: boolean; }>`
  display: ${({ isSelected }) => !isSelected && 'none'};
`;