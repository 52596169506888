import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';
import * as Shared from 'components/style';

import * as SharedHome from '../style';

export const Exhibition = styled.div`
  ${SharedHome.gridBorderStyle()}
  position: relative;

  height: calc(100vh - ${Shared.PRIMARY_NAV_HEIGHT} - ${Shared.TICKER_HEIGHT});
`;

export const ArtworkCanvas = styled.div`
  position: absolute;
  top: ${Shared.PRIMARY_NAV_HEIGHT};
  left: 0; 
  right: 0; 
  bottom: 0; 

  z-index: ${Shared.Z_INDEX.EXHIBITION_BASE};
`;

export const Label = styled.div<{ theme: CustomTheme }>`
  max-width: 100%;
  position: absolute;
  bottom: 0; 
  right: 0; 
  padding: ${SPACER.xsmall} ${SPACER.base};
  outline: 1px solid;
  z-index: ${Shared.Z_INDEX.EXHIBITION_LABELS};
  
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color .25s ease-out;
  
  font-size: 14px;
  line-height: 1.25;
  white-space: pre-wrap;
`;

export const Title = styled.span`
  font-weight: bold;
  font-style: italic;
`;

export const Date = styled.span`
  font-style: italic;
`;