import React from 'react';

import * as Styled from './style';

const ExhibitionNav = ({
  currentExhibitIndex,
  exhibitsCount,
  handleNavPreviousClick,
  handleNavNextClick,
} : {
  currentExhibitIndex: number;
  exhibitsCount: number;
  handleNavPreviousClick: () => void;
  handleNavNextClick: () => void;
}) => (
  <Styled.ExhibitionNav>
    <Styled.NavButton onClick={handleNavPreviousClick}>
      ←
    </Styled.NavButton>
      
    <Styled.Wayfinder>
      {currentExhibitIndex + 1} <span>/</span> {exhibitsCount}
    </Styled.Wayfinder>
    
    <Styled.NavButton onClick={handleNavNextClick}>
      →
    </Styled.NavButton>
  </Styled.ExhibitionNav>
);

export default ExhibitionNav;