import styled from '@emotion/styled';

import CustomTheme from 'interfaces/CustomTheme';
import * as Shared from 'components/style';

import * as SharedHome from '../style';

export const Ticker = styled.div<{ theme: CustomTheme }>`
  ${SharedHome.gridBorderStyle()}
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color .25s ease-out;

  position: sticky; 
  top: ${Shared.PRIMARY_NAV_HEIGHT}; 
  height: ${Shared.TICKER_HEIGHT};

  display: flex; 
  align-items: center;
  justify-content: flex-start;
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
`;

export const Marquee = styled.div<{ isPaused: boolean; }>`
  animation: marquee 96s linear infinite; 
  animation-play-state: ${({ isPaused }) => isPaused ? 'paused' : 'running'};

  @keyframes marquee {
    0% {
        transform: translate3d(16px, 0, 0);
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
  }

  > ul {
    font-family: unset; 
    font-size: unset; 
    line-height: unset; 
    margin: unset; 
    max-width: unset; 

    list-style: none; 
    list-style: inside "✸"; 
    display: flex; 
    align-items: center;

    li::before, li::after {
      content: " ";
      white-space: pre;
    }
  }
`;