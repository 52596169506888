import React, { ReactNode } from 'react';
import { Link } from 'gatsby';

// https://mdawar.dev/blog/mdx-open-links-in-new-page

const CustomLink = ({ 
  children, 
  href, 
} : {
  children?: ReactNode;
  href: string;
}) => {
  if (href.startsWith('/')) {
    return <Link to={href}>{children}</Link>;
  }

  const onPage = href.startsWith('#');

  return (
    <a
      href={href}
      target={onPage ? undefined : '_blank'}
      rel={onPage ? undefined : 'noopener noreferrer'}
    >
      {children}
    </a>
  );
};

export default CustomLink; 