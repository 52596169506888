import React, { useState } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import useTickerContentQuery from 'hooks/useTickerContentQuery';
import CustomLink from 'components/customLink';

import * as Styled from './style';

const Ticker = () => {
  const [isPaused, setIsPaused] = useState(false);
  const { body } = useTickerContentQuery(); 

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLElement).nodeName === 'A') {
      setIsPaused(true);

      return;
    }

    setIsPaused(prevState => !prevState);
  };

  return(
    <Styled.Ticker role="button" onClick={handleClick}>
      <Styled.Marquee isPaused={isPaused}>
        <MDXProvider
          components={{
            a: CustomLink,
          }}
        >
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </Styled.Marquee>
    </Styled.Ticker>
  );
};

export default Ticker;