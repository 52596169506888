import React, { useState } from 'react';

import { FEATURED_EXHIBITS } from 'components/exhibits';
import CustomLink from 'components/customLink';

import ExhibitionNav from './exhibitionNav';
import * as Styled from './style';

const Exhibition = () => {
  const [currentExhibitIndex, setCurrentExhibitIndex] = useState(0);
  const exhibitsCount = FEATURED_EXHIBITS.length;

  const { 
    Component: ArtworkComponent, 
    title, 
    createdAt, 
    technique, 
  } = FEATURED_EXHIBITS[currentExhibitIndex];

  const handleNavPreviousClick = () => {
    setCurrentExhibitIndex(prevState => Math.abs((prevState - 1) % exhibitsCount));
  };

  const handleNavNextClick = () => {
    setCurrentExhibitIndex(prevState => (prevState + 1) % exhibitsCount);
  };
  
  return(
    <Styled.Exhibition>
      <ExhibitionNav 
        currentExhibitIndex={currentExhibitIndex}
        exhibitsCount={exhibitsCount}
        handleNavPreviousClick={handleNavPreviousClick}
        handleNavNextClick={handleNavNextClick}
      />
      <Styled.ArtworkCanvas>
        <ArtworkComponent />
      </Styled.ArtworkCanvas>
      <Styled.Label>
        <Styled.Title>{title}, </Styled.Title><Styled.Date>{getYearFromDate(createdAt)}</Styled.Date>.{' '}
        <span dangerouslySetInnerHTML={{ __html: technique}} />
      </Styled.Label>
    </Styled.Exhibition>
  );
};

const getYearFromDate = (date: string) => (
  new Date(date).getFullYear()
);

export default Exhibition;