import { graphql, useStaticQuery } from 'gatsby';

const useTickerContentQuery = () => {
  const { allMdx: { nodes } } = useStaticQuery(graphql`
    query TICKER_CONTENT_QUERY {
      allMdx(
        filter: { fileAbsolutePath: { regex : "\/content\/blog\/homepage-ticker-content/" } }
      ) {
        nodes {
          body
        }
      }
    }
  `);

  return nodes[0]; 
};

export default useTickerContentQuery;