import styled from '@emotion/styled';

import { SPACER } from 'consts';
import * as Shared from 'components/style';
import CustomTheme from 'interfaces/CustomTheme';

export const ExhibitionNav = styled.nav<{ theme: CustomTheme; }>`
  position: absolute; 
  top: ${Shared.PRIMARY_NAV_HEIGHT};
  right: 0;

  z-index: ${Shared.Z_INDEX.EXHIBITION_LABELS};

  display: flex;
  align-items: center;
  outline: 1px solid;

  ${({ theme }) => `
    background-color: ${theme.colors.background};
    transition: background-color .25s ease-out;
    color: ${theme.colors.text};
  `}
`;

export const Wayfinder = styled.div<{ theme: CustomTheme; }>`
  padding: 0 ${SPACER.small};
  font-weight: bold;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.monospace};

  > span { 
    font-weight: normal;
  }
`;

export const NavButton = styled.button<{ theme: CustomTheme; }>`
  border: 0;
  cursor: pointer;
  font-size: 24px; 
  font-weight: bold; 
  height: ${SPACER.x2large};
  width: ${SPACER.x2large};
  line-height: 1;

  ${({ theme }) => `
    font-family: ${theme.fonts.monospace};
    background-color: ${theme.colors.background};
    transition: background-color .25s ease-out;
    color: ${theme.colors.text};
  `}
`;