import React, { useState, useEffect } from 'react';
import { Button } from 'theme-ui';

import { Nav } from '../style';
import * as Styled from './style';

const getRandomColor = () => (
  `#${(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')}`
);

const randomizeColors = (numColors: number) => (
  Array.from({ length: numColors || 0}, () => getRandomColor())
);

const InteractiveSvg = ({ 
  svgMarkup,
  isAllPatternsSelected,
  resetAllOverride,
  setResetAllOverride,
} : { 
  svgMarkup: string; 
  isAllPatternsSelected: boolean;
  resetAllOverride: boolean;
  setResetAllOverride: (arg0: boolean) => void;
}) => {
  const [areColorsReset, setAreColorsReset] = useState(true);

  const rawPaths = svgMarkup.match(/ d="(.*?)"/g);
  const rawFills = svgMarkup.match(/fill="(.*?)"/g);
  
  const paths = rawPaths?.map(path => path.split('"')[1]);
  const fills = rawFills?.map(fill => fill.split('"')[1]);

  const [fillColors, setFillColors] = useState((areColorsReset || resetAllOverride) ? (fills || []) : randomizeColors(paths?.length || 0));

  useEffect(() => {
    if (resetAllOverride) {
      setFillColors(fills || []);
    }
  }, [resetAllOverride]);

  if (typeof window === 'undefined' || !paths || !fills) {
    return null;
  }

  const handlePathClick = ({ idx }: { idx: number; }) => {
    if (areColorsReset || resetAllOverride) {
      setAreColorsReset(false);
      setResetAllOverride(false);
    }

    const newFillColors = [...fillColors];
    newFillColors[idx] = getRandomColor(); 

    setFillColors(newFillColors);
  };

  return(
    <Styled.PatternContainer>
      <svg viewBox="0 0 343 465" xmlns="http://www.w3.org/2000/svg">
        {paths.map((d, idx) => (
          <path 
            key={`${idx}-${d.slice(0,8)}`}
            d={d}
            fill={(areColorsReset || resetAllOverride) ? fills[idx] : fillColors[idx]}
            onClick={() => handlePathClick({ idx })}
          />
        ))}
      </svg>

      {!isAllPatternsSelected && (
        <Nav>
          <Button 
            variant="secondary"
            onClick={() => {
              setAreColorsReset(true);
              setFillColors(fills);
            }}
            disabled={(areColorsReset || resetAllOverride) === true}
          >
            Reset
          </Button>

          <Button 
            variant="primary" onClick={() => {
              setAreColorsReset(false);
              setResetAllOverride(false);
              setFillColors(randomizeColors(paths?.length || 0));
            }}
          >
            Randomize
          </Button>
        </Nav>
      )}
    </Styled.PatternContainer>
  );
};

export default InteractiveSvg;