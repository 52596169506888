import Bottles from './artwork/bottles';
import RisingTide from './artwork/risingTide';
import Patterns from './artwork/patterns';

type FeaturedExhibitType = {
  Component: React.FC<any>;
  title: string;
  createdAt: string;
  technique: string;
}

export const FEATURED_EXHIBITS: FeaturedExhibitType[] = [
  RisingTide,
  Patterns,
  Bottles,
];