import { css } from '@emotion/core';

import { SPACER } from 'consts';
import * as Shared from 'components/style';

export const gridBorderStyle = ({ shouldIncludeTop=false } = {}) => css`
  width: 100%;
  border: 1px solid;

  ${!shouldIncludeTop && 'border-top: 0;'}
`;

export const sectionStyle = css`
  border: 1px dashed;
  border-top: 0;
  min-height: calc(100vh - (${Shared.PRIMARY_NAV_HEIGHT} + ${Shared.TICKER_HEIGHT} + ${Shared.PRIMARY_NAV_HEIGHT}));

  display: flex; 
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  text-align: center; 

  padding: ${SPACER.base};
`;